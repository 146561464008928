<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <div class="pull-left">
              <h4 class="card-title" style="text-align: center">Qo'yilgan imzolar ro'yxati</h4>
            </div>
            <div class="pull-right">
              <div class="input-group search-box">
                <input class="form-control" type="text" :placeholder="'Izlash...'" v-model="filter.search" @keypress.enter="initList">
                <div class="input-group-append">
                  <button class="btn btn-info" @click="initList">
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body table-responsive">
            <table class="table table-hover">
              <thead>
              <tr>
                <th class="app-w-10p">App</th>
                <th class="app-w-20p">Instance</th>
                <th class="app-w-10p">Entity ID</th>
                <th class="app-w-10p">Entity</th>
                <th class="app-w-10p">Sign Type</th>
                <th class="app-w-15p d-none d-md-table-cell">Imzolovchi</th>
                <th class="app-w-15p d-none d-md-table-cell">Vaqti</th>
                <th class="app-w-10p">Status</th>
                <th></th>
              </tr>
              </thead>
              <tbody v-if="!loading && results && results.data">
              <tr v-for="result in results.data" :key="result.id">
                <td>{{ result.signRequest.appName }}</td>
                <td>{{ result.signRequest.instance.name }}</td>
                <td>{{ result.signRequest.entityId }}</td>
                <td>{{ result.signRequest.entityType }}</td>
                <td>{{ result.signedDocument.espType }}</td>
                <td class="d-none d-md-table-cell">
                  <div v-if="result.createdBy">{{ result.createdBy.fullName }}</div>
                </td>
                <td class="d-none d-md-table-cell">
                  <div v-if="result.createdDate">{{ result.createdDate }}</div>
                </td>
                <td>
                  <span class="bg-success" v-if="result.status === 'DONE'">
                    {{ result.status }}
                  </span>
                  <span class="bg-danger" v-if="result.status === 'FAILED'">
                    {{ result.status }}
                  </span>
                  <span class="bg-warning" v-if="result.status === 'PENDING'">
                    {{ result.status }}
                  </span>
                </td>
                <td>
                  <div class="btn-group btn-group-sm">
                    <router-link :to="'/sign/view/' + result.id" tag="button" class="btn btn-success" v-if="hasAction('SIGN_VIEW')">
                      <i class="fa fa-pencil"></i>
                    </router-link>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="text-center">
              <div class="loader mt-5 mb-5" v-if="loading"></div>
              <span class="no-data mt-5 mb-5" v-if="!loading && results && results.data && results.data.length===0">Нет данных</span>
            </div>
            <div class="row" v-if="!loading && results">
              <div class="col-2 pt-3 pb-3 text-left">
                <div class="pl-3">
                  <b>Umumiy : <i><span>{{ results.total | currency('', 0, { thousandsSeparator: ' ' })}}</span></i></b>
                </div>
              </div>
              <div class="col-10 pt-3 pb-3">
                <b-pagination :total-rows="results ? results.total : 0" v-model="filter.page.page" :per-page="filter.page.size" @change="onPageChange"></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {BPagination} from 'bootstrap-vue'
import initFilterModel from "@/shared/filter"

export default {
  name: "SignList",
  components: {
    'b-pagination': BPagination
  },
  data() {
    return {
      loading: false,
      filter: initFilterModel(),
      results: null,
    }
  },
  methods: {
    initList() {
      this.loading = true
      this.$http.post('sign/list', this.filter)
          .then(res => {
            this.results = res.data
            this.loading = false
          }, err => {
            console.log(err)
            this.loading = false
          })
    },
    onPageChange(page) {
      this.filter.page.page = page
      this.initList()
    }
  },
  created() {
    this.initList()
  }
}
</script>

<style scoped>

</style>